import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const ImageWithCaption = makeShortcode("ImageWithCaption");
const GridList = makeShortcode("GridList");
const GridListItem = makeShortcode("GridListItem");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-tables"
            }}>{`How to use Tables`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#types-of-table-cells"
            }}>{`Types of table cells`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#styles-of-table-cells"
            }}>{`Styles of table cells`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#table-cell-styles"
            }}>{`Table cell styles`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#text-indicators-and-supporting-text-options"
            }}>{`Text indicators and supporting text options`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#tables-for-mobile"
            }}>{`Tables for mobile`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#content-guidelines"
            }}>{`Content guidelines`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-tables",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-tables",
        "aria-label": "how to use tables permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use Tables`}</h2>
    <p>{`Use tables to make information easier to compare and scan for users.`}</p>
    <p>{`When using tables:`}</p>
    <ul>
      <li parentName="ul">{`List your items in a clear way, whether it’s alphabetical, numerical, time-based or some other logical order`}</li>
      <li parentName="ul">{`Complex tables may require more explicit cell and header associations using scope, id, and headers attributes`}</li>
      <li parentName="ul">{`Provide a summary for complex tables describing how the data is organised to help users understand and navigate the table`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "types-of-table-cells",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#types-of-table-cells",
        "aria-label": "types of table cells permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Types of table cells`}</h2>
    <p>{`A table is built using two cell types, a header cell and a table cell.`}</p>
    <h3>{`Header cells`}</h3>
    <p>{`Header cells are used to tell the user what every row and column is showing within your table. Header cells can be used in horizontal format (row) or vertical format (column) to build a table.`}</p>
    <ImageWithCaption imageName="table-example-1" type="png" alt="examples of how to display vertical and horizontal header cells" mdxType="ImageWithCaption" />
    <h3>{`Table cells`}</h3>
    <p>{`Table cells are the building blocks designed to help you build data tables with content.`}</p>
    <ImageWithCaption imageName="table-example-2" type="png" alt="examples of how to display vertical and horizontal table cells" mdxType="ImageWithCaption" />
    <h4>{`Table cell colours`}</h4>
    <p>{`Table cells can be designed using zebra stripes to help differentiate your data and make the table easier to read.`}</p>
    <ImageWithCaption imageName="table-example-3" type="png" alt="example to display zebra stripes for table rows" mdxType="ImageWithCaption" />
    <h4>{`Table cell & header cell alignment`}</h4>
    <p>{`Header cells and table cells allow for alignment for the elements within each cell. This allows you to adjust the layout of your rows and columns to best reflect the data type and format within each cell.`}</p>
    <p>{`Alignment options within cells are:`}</p>
    <ul>
      <li parentName="ul">{`Left`}</li>
      <li parentName="ul">{`Center`}</li>
      <li parentName="ul">{`Right`}</li>
    </ul>
    <hr></hr>
    <h2 {...{
      "id": "styles-of-table-cells",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#styles-of-table-cells",
        "aria-label": "styles of table cells permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Styles of table cells`}</h2>
    <h3>{`Header cell styles`}</h3>
    <p>{`Elements and states can be edited within each header cell.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Text only</h4>
    <ImageWithCaption imageName="table-example-4" type="png" alt="text only table header cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Icon only</h4>
    <ImageWithCaption imageName="table-example-5" type="png" alt="icon only table header cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Icon & Text</h4>
    <ImageWithCaption imageName="table-example-6" type="png" alt="icon and text table header cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Checkbox</h4>
    <ImageWithCaption imageName="table-example-7" type="png" alt="checkbox table header cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <h2 {...{
      "id": "table-cell-styles",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#table-cell-styles",
        "aria-label": "table cell styles permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Table cell styles`}</h2>
    <p>{`Elements and states can be edited within each table cell.`}</p>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Text only</h4>
    <ImageWithCaption imageName="table-example-8" type="png" alt="text only table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Icon only</h4>
    <ImageWithCaption imageName="table-example-9" type="png" alt="icon only table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Icon & Text</h4>
    <ImageWithCaption imageName="table-example-10" type="png" alt="icon and text table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Checkbox</h4>
    <ImageWithCaption imageName="table-example-11" type="png" alt="checkbox table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Button</h4>
    <ImageWithCaption imageName="table-example-12" type="png" alt="button table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Radio</h4>
    <ImageWithCaption imageName="table-example-13" type="png" alt="radio table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Input</h4>
    <ImageWithCaption imageName="table-example-14" type="png" alt="input table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Switch</h4>
    <ImageWithCaption imageName="table-example-15" type="png" alt="switch table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Link</h4>
    <ImageWithCaption imageName="table-example-16" type="png" alt="link table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Tag</h4>
    <ImageWithCaption imageName="table-example-17" type="png" alt="tag table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <GridList columns="2" mdxType="GridList">
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Image</h4>
    <ImageWithCaption imageName="table-example-18" type="png" alt="image table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
  <GridListItem removePadding="true" mdxType="GridListItem">
    <h4>Swap Instance</h4>
    <ImageWithCaption imageName="table-example-19" type="png" alt="swap instance table cell example" mdxType="ImageWithCaption" />
  </GridListItem>
    </GridList>
    <hr></hr>
    <h2 {...{
      "id": "text-indicators-and-supporting-text-options",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#text-indicators-and-supporting-text-options",
        "aria-label": "text indicators and supporting text options permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Text indicators and supporting text options`}</h2>
    <p>{`There are three types of text indicators that you can apply to your table interface patterns which are:`}</p>
    <ul>
      <li parentName="ul">{`A label to indicate the name of the table and its purpose`}</li>
      <li parentName="ul">{`Supporting text to provide additional information or instructions about the table`}</li>
      <li parentName="ul">{`Captin text that can be added at the base of tables`}</li>
    </ul>
    <h3>{`Label example`}</h3>
    <p>{`When using a label:`}</p>
    <ul>
      <li parentName="ul">{`Always left align your label above the table.`}</li>
      <li parentName="ul">{`Spacing between the table and the label should be 8px.`}</li>
    </ul>
    <ImageWithCaption imageName="table-example-20" type="png" alt="Table lable displayed above the table" mdxType="ImageWithCaption" />
    <h3>{`Label with supporting text example`}</h3>
    <p>{`When using supporting text with a label:`}</p>
    <ul>
      <li parentName="ul">{`Always left align your label and supporting text above the table`}</li>
      <li parentName="ul">{`Spacing between the table, the supporting text, and the label should be 8px`}</li>
      <li parentName="ul">{`keep the length of your supporting text to less than 50% of the width of your table in most cases, unless the table is on mobile and you can use the full viewport width`}</li>
    </ul>
    <ImageWithCaption imageName="table-example-21" type="png" alt="Table lable and supporting text displayed above the table" mdxType="ImageWithCaption" />
    <h3>{`Caption text example`}</h3>
    <p>{`When using caption text:`}</p>
    <ul>
      <li parentName="ul">{`Always left align your caption text with the table`}</li>
      <li parentName="ul">{`Spacing between the table, the caption text should be 8px`}</li>
      <li parentName="ul">{`Keep the length of your caption text to less than 50% of the width of your table in most cases, unless the table is on mobile and you can use the full viewport width`}</li>
    </ul>
    <ImageWithCaption imageName="table-example-22" type="png" alt="Caption text displayed below the table" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "tables-for-mobile",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#tables-for-mobile",
        "aria-label": "tables for mobile permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Tables for mobile`}</h2>
    <p>{`For mobile table used there is a naturally smaller viewport on width. For this screen size you can use the cell type ‘Row = Header + Cell’.`}</p>
    <ImageWithCaption imageName="table-example-23" type="png" alt="Table displayed on mobile view" mdxType="ImageWithCaption" />
    <h3>{`Displaying table content on mobile devices`}</h3>
    <p>{`There are of course restrictions due to the smaller screen size which limits the amount of columns you can use. It is suggested to keep tables for mobiles to a 2 column split so that content does not look squashed and would potentially become unreadable.`}</p>
    <p>{`If the content of your table involves many rows and columns of information the following scrolling mechanisms (Vertical & horizontal) are recommended.`}</p>
    <ImageWithCaption imageName="table-example-24" type="png" alt="Table vertical and horizontal scrolling on mobile view" mdxType="ImageWithCaption" />
    <hr></hr>
    <h2 {...{
      "id": "content-guidelines",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#content-guidelines",
        "aria-label": "content guidelines permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Content guidelines`}</h2>
    <p>{`When writing content for tables:`}</p>
    <ul>
      <li parentName="ul">{`All labels and headers should be informative and descriptive`}</li>
      <li parentName="ul">{`Include unit of measurement symbols in the header so they aren’t repeated throughout the columns`}</li>
      <li parentName="ul">{`Use decimals consistently`}</li>
      <li parentName="ul">{`Start your headers with a capital letter`}</li>
      <li parentName="ul">{`Don’t use commas or any punctuation at the end of each header`}</li>
      <li parentName="ul">{`Don’t use more than three words per header`}</li>
    </ul>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  Our team can answer any questions about using tables or give you a helping
  hand with your next project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      